<template>
	<div style="position: relative">
		<div v-nk-loading="loading">
			<div class="home-header-wrap" :style="{backgroundImage: 'url(https://gogo2go-1253436512.cos.accelerate.myqcloud.com/Malaysia/takeout-admin/2023/06/e756836e7d1b5050b10fe2cd535a35b3.png)'}">
				<img v-show="backImgData.backGif" :src="backImgData.backGif" alt class="home-header-image" />
				<div class="home-video-notice">
					<div>
						<span class="video-notice">Everything to go!</span>
					</div>
					<div>
						<span class="video-origin">Settled in Gogo2go food delivery good business</span>
					</div>
					<div class="d-flex flex-wrap button-two">
						<button class="video-btn primary-enter-btn" @click="toSettled()">
							<span class="real-btn">{{ $t("home.moveNow") }}</span>
						</button>
					</div>
				</div>
				<div class="adaptive-main adaptive-width" style="margin: 0 20%;">
					<div class="announcement-wrap" style="overflow-x: auto">
						<div class="announcement-wrap-box">
							<span class="iconfont color-ori">&#xea7e;</span>
						</div>
						<span style="margin-right: 35px" class="cursor-pointer">
							<span>GOGO2GO 购物应用app</span>
						</span>
					</div>
				</div>
			</div>
			<div class="home-main-info">
				<div class="top-main-info">
					<h2 class="link-company-title">链接千亿全球市场</h2>
					<h3>海量商机 轻松触达</h3>
				</div>
				<div class="home-title-hover">
					<div class="home-data-item" :class="hoverImg === 0 ? 'home-data-item-hover' : ''" ref="moreWidth" @mouseover="moverImg(0)">
						<div class="home-data-short" @mouseenter="stopRoll()" @mouseleave="removeRoll()">
							<img class="icon-data-type" v-real-img="'https://cdn1.niuke-erp.com/def/image_6838acefb2c0d2b1ce9fe6f64ce6975f.png'" src alt />
							<div class="home-data-content">
								<div class="home-data-num">4.4</div>
								<div class="home-data-part">
									<div class="home-data-unit">亿+</div>
									<div class="home-data-desc">东南亚用户</div>
								</div>
							</div>
						</div>
						<div class="home-data-long d-flex align-items-center" style="height: 100%" ref="moreImage">
							<img v-real-img="'https://cdn1.niuke-erp.com/def/image_11ddf6be7a038f9e8c662daa43e5a581.jpg'" alt />
						</div>
					</div>
					
					<div class="home-data-item" :class="hoverImg === 1 ? 'home-data-item-hover' : ''" ref="moreWidth" @mouseover="moverImg(1)">
						<div class="home-data-short" @mouseenter="stopRoll()" @mouseleave="removeRoll()">
							<img class="icon-data-type" v-real-img="'https://cdn1.niuke-erp.com/def/image_bbbfba427ce911594bf573602101763a.png'" src alt />
							<div class="home-data-content">
								<div class="home-data-num">2</div>
								<div class="home-data-part">
									<div class="home-data-unit">大市场</div>
									<div class="home-data-desc">蕴含千亿消费力</div>
								</div>
							</div>
						</div>
						<div class="home-data-long d-flex align-items-center" style="height: 100%" ref="moreImage">
							<img v-real-img="'https://gogo2go-1253436512.cos.accelerate.myqcloud.com/Malaysia/takeout-admin/2023/06/d3345cfa9f47d5f7604ad7124113b92f.jpg'" alt />
						</div>
					</div>
					
					<div class="home-data-item" :class="hoverImg === 2 ? 'home-data-item-hover' : ''" ref="moreWidth" @mouseover="moverImg(2)">
						<div class="home-data-short" @mouseenter="stopRoll()" @mouseleave="removeRoll()">
							<img class="icon-data-type" v-real-img="'https://cdn1.niuke-erp.com/def/image_370212c929d73b837bbb324fb6e37af4.png'" src alt />
							<div class="home-data-content">
								<div class="home-data-num">51</div>
								<div class="home-data-part">
									<div class="home-data-unit">顾客-订单</div>
									<div class="home-data-desc">%转化率</div>
								</div>
							</div>
						</div>
						<div class="home-data-long d-flex align-items-center" style="height: 100%" ref="moreImage">
							<img v-real-img="'https://gogo2go-1253436512.cos.accelerate.myqcloud.com/Malaysia/takeout-admin/2023/06/973d386e9c7f59ef24857b3a38040e86.jpg'" alt />
						</div>
					</div>
					
					<div class="home-data-item" :class="hoverImg === 3 ? 'home-data-item-hover' : ''" ref="moreWidth" @mouseover="moverImg(3)">
						<div class="home-data-short" @mouseenter="stopRoll()" @mouseleave="removeRoll()">
							<img class="icon-data-type" v-real-img="'https://cdn1.niuke-erp.com/def/image_6b884bf3b21080a50cc7a2eddbb98ef5.png'" src alt />
							<div class="home-data-content">
								<div class="home-data-num">500</div>
								<div class="home-data-part">
									<div class="home-data-unit">销售额</div>
									<div class="home-data-desc">W+</div>
								</div>
							</div>
						</div>
						<div class="home-data-long d-flex align-items-center" style="height: 100%" ref="moreImage">
							<img v-real-img="'https://cdn1.niuke-erp.com/def/image_eadf1b1d7fc95fa796ec153830cf7f4e.jpg'" alt />
						</div>
					</div>
					
					<div class="home-data-item" :class="hoverImg === 4 ? 'home-data-item-hover' : ''" ref="moreWidth" @mouseover="moverImg(4)">
						<div class="home-data-short" @mouseenter="stopRoll()" @mouseleave="removeRoll()">
							<img class="icon-data-type" v-real-img="'https://cdn1.niuke-erp.com/def/image_017fd6c00dded9d791139f0ff2a6a8fe.png'" src alt />
							<div class="home-data-content">
								<div class="home-data-num">10</div>
								<div class="home-data-part">
									<div class="home-data-unit">商品单月曝光量</div>
									<div class="home-data-desc">W+</div>
								</div>
							</div>
						</div>
						<div class="home-data-long d-flex align-items-center" style="height: 100%" ref="moreImage">
							<img v-real-img="'https://cdn1.niuke-erp.com/def/image_4f9ced88bb3e64c0a14d4c4480c89b24.jpg'" alt />
						</div>
					</div>
					
				</div>
				<div class="min-show">
					<div class="swiper-div">
						<swiper class="swiper-container requireSwiper" :options="swiperOptionsMin">
							<div class="swiper-slide" v-for="(item, index) in rollArray" :key="index">
								<div>
									<div class="lun-img">
										<img v-real-img="item.rightImage" src alt />
									</div>
									<div class="home-data-short">
										<img class="icon-data-type" src v-real-img="item.leftImage" alt />
										<div class="home-data-content">
											<div class="home-data-num">{{ item.amountNum }}</div>
											<div class="home-data-part">
												<div class="home-data-unit">{{ item[getLangType("unitTitle")] }}</div>
												<div class="home-data-desc">{{ item[getLangType("tipValue")] }}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</swiper>
					</div>
				</div>
			</div>
			<div v-if="scrollContent" class="web-show" style="background: #fff;overflow: hidden;padding-top: -80px;min-width: 1400px;">
				<div class="pron-box" style="position: relative;height: 700px;min-width: 1400px; margin-top: 500px;">
					<div class="item__1zkDK" ref="pron" :style="{transform: `translateY(${backTrfSize}px) translateX(-50%) scale(${backScaleSize})`}" style="left: 50%">
						<img class="animate-box-shadow iNAsFOsRx9" style="min-height: 400px" src="https://gogo2go-1253436512.cos.accelerate.myqcloud.com/Malaysia/takeout-admin/2023/06/f9eec172db4c1611e8b764f72d7e6a62.jpg" />
					</div>
					<div class="item__1zkDK" :style="{ opacity: centerOpacitySize }" style="left: 50%;transform: translateX(-50%) translateY(-150px) scale(1);transform-origin: center bottom;">
						<img class="JLsfXkagL4" src="https://gogo2go-1253436512.cos.accelerate.myqcloud.com/Malaysia/takeout-admin/2023/06/3142158961e3b88595ab50d8da33b4c2.png" />
					</div>
					<div class="item__1zkDK" :style="{ opacity: centerOpacitySize }" style="left: 50%; transform: translateX(-50%) translateY(130px)">
						<div class="Solaszz6kZ">
							<h1 class="CokEr5g7X1">Professional store promotion solutions</h1>
							<p style="margin-top: 10px; font-size: 14px" class="d-flex justify-content-center">Easily increase order volume</p>
						</div>
					</div>
					<div class="item__1zkDK" :style="{opacity: imageOneOpacity,transform: `scale(${imageOneScale}) translateY(${imageOneTranY}px) translateX(${imageOneTranX}px)`, }">
						<img class="animate-box-shadow snMLzW9nMO" :src="'https://gogo2go-1253436512.cos.accelerate.myqcloud.com/Malaysia/takeout-admin/2023/06/1ea7f92ba4a326862aeaab780f27de04.png'" alt />
					</div>
					<div class="item__1zkDK" :style="{ opacity: imageTwoOpacity,transform: `scale(${imageTwoScale}) translateY(${imageTwoTranY}px) translateX(${imageTwoTranX}px)`,}">
						<img class="animate-box-shadow AZlwEUmi9b" :src="'https://gogo2go-1253436512.cos.accelerate.myqcloud.com/Malaysia/takeout-admin/2023/06/f1b394773a66b24018f596a4803108b3.png'" alt />
					</div>
					<div class="item__1zkDK" :style="{opacity: imageThreeOpacity,transform: `scale(${imageThreeScale}) translateY(${imageThreeTranY}px) translateX(${imageThreeTranX}px)`,}">
						<img class="animate-box-shadow ahBFJQpZfJ" :src="'https://gogo2go-1253436512.cos.accelerate.myqcloud.com/Malaysia/takeout-admin/2023/06/66309b45aa95e977c4d6bb0658bde1df.png'" alt />
					</div>
					<div class="item__1zkDK" :style="{opacity: imageFourOpacity,transform: `scale(${imageFourScale}) translateY(${imageFourTranY}px) translateX(${imageFourTranX}px)`,}">
						<img class="animate-box-shadow T9nfP9hbVY" :src="'https://gogo2go-1253436512.cos.accelerate.myqcloud.com/Malaysia/takeout-admin/2023/06/6af6be3f28f44e1ee3b2410334823e8c.png'" alt />
					</div>
				</div>
			</div>

			<div class="home-module home-banner">
				<div class="home-module-word">
					<div class="home-module-title">
						<h2 class="home-module-title">gogo2go的优势</h2>
					</div>
					<h3 class="home-module-content">将店铺平均销售额提升60%</h3>
				</div>
			</div>
			<div class="solution-plan adaptive-width">
				<el-row :gutter="30">
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
						<img v-real-img="'https://gogo2go-1253436512.cos.accelerate.myqcloud.com/Malaysia/takeout-admin/2023/06/d3043124ab55408bfac6689ea73056bb.png'" src width="300" alt />
						<h3 class="item-light">多元化用户群</h3>
						<p class="item-text">广泛且多样化的用户，拓展商户的市场覆盖</p>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
						<img v-real-img="'https://cdn1.niuke-erp.com/def/image_8a9aa18e47984ff1d2fd5c9f03c756b0.png'" src width="300" alt />
						<h3 class="item-light">买卖便捷安全</h3>
						<p class="item-text">多种在线支付方式，满足店铺交易需求</p>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
						<img v-real-img="'https://cdn1.niuke-erp.com/def/image_aef2bbf069201a72a63dfce0f983f88e.png'" src width="300" alt />
						<h3 class="item-light">强大营销支持</h3>
						<p class="item-text">全方位营销支持，助商户实现业务增长</p>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
						<img v-real-img="'https://cdn1.niuke-erp.com/def/image_74d3d6aaacc2f4f32fac45823ce0095f.png'" src width="300" alt />
						<h3 class="item-light">优质客户服务</h3>
						<p class="item-text">高度本地化专业客服团队，随时为您解决问题</p>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
						<img v-real-img="'https://cdn1.niuke-erp.com/def/image_e3cb28082024e1d31c9cb3616ce47094.png'" src width="300" alt />
						<h3 class="item-light">先进技术平台</h3>
						<p class="item-text">高效订单管理、实时配送跟踪，提升商户效率和用户体验</p>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
						<img v-real-img="'https://cdn1.niuke-erp.com/def/image_96978dfcd7c9111ba2657330a7a4b7d9.png'" src width="300" alt />
						<h3 class="item-light">数据驱动支持</h3>
						<p class="item-text">基于数据的市场洞察和战略建议，助商户优化运营</p>
					</el-col>
				</el-row>
			</div>
			<div class="home-module home-banner">
				<div class="home-module-word">
					<div class="home-module-title">
						<h2 class="home-module-title">入驻即享3大权益</h2>
					</div>
					<h3 class="home-module-content">让您的店铺快速获得线上用户</h3>
				</div>
			</div>
			<div class="adaptive-width d-flex">
				<div class="adaptive-choose w-100">
					<el-row :gutter="30">
						<el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
							<div class="centralToChoose min-width-300">
								<div class="equity-content">
									<img class="icon-data-type" v-real-img="'https://cdn1.niuke-erp.com/def/image_90d818269a1d6584c4ae1af988eea71d.png'" alt="icon" />
									<h3 class="equity-title">特派招商经理</h3>
									<p>
										配备专业招商经理细致解答各种疑问
										<br />
										跟进入驻流程，贴心为您服务
									</p>
								</div>
								<img v-real-img="'https://cdn1.niuke-erp.com/def/image_1f5090764d680dba79fe36eca55e2524.png'" alt width="200" height="200" style="position: absolute; right: 10%" />
							</div>
						</el-col>
						<el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
							<div class="centralToChoose min-width-300">
								<div class="equity-content">
									<img class="icon-data-type" v-real-img="'https://cdn1.niuke-erp.com/def/image_55bc610cd505317e547abf0be7ddca52.png'" alt="icon" />
									<h3 class="equity-title">活动曝光资源</h3>
									<p>
										店铺商户享有大量活动曝光资源
										<br />
										助您轻松起步,快速成长
									</p>
								</div>
								<img v-real-img="'https://cdn1.niuke-erp.com/def/image_f45db4f12fab12de48d7654278ca924a.png'" alt width="200" height="200" style="position: absolute; right: 10%" />
							</div>
						</el-col>
						<el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
							<div class="centralToChoose min-width-300">
								<div class="equity-content">
									<img class="icon-data-type" v-real-img="'https://cdn1.niuke-erp.com/def/image_953d81fad009b2d6b989b035862d57f5.png'" alt="icon" />
									<h3 class="equity-title">新店政策支持</h3>
									<p>
										新入驻店铺享受多项政策支持，助您轻松达成交易
										<br />
									</p>
								</div>
								<img v-real-img="'https://cdn1.niuke-erp.com/def/image_8910bd531d0e95cc2696bcbef8495648.png'" alt width="200" height="200" style="position: absolute; right: 10%" />
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
			<div class="home-module home-partner">
				<div class="home-module-word">
					<div class="home-module-text">合作伙伴</div>
					<div class="home-module-center" style="text-align: center">出海得力助手</div>
				</div>
				<div class="partner-wrap web-show">
					<swiper ref="mySwiper2" v-if="brandArr.length > 0" class="swiper-container requireSwiper swiper-no-swiping" :options="swiperWraps">
						<div class="swiper-slide slideData" v-for="(item, index) in brandArr" :key="index" @click="toRouterBtn(item.partnerLink)">
							<img :src="item.partners" style="width: 160px" alt />
						</div>
						<div class="jumpButton-next swiper-left swiper-dis swiper-button-prev" slot="button-prev">
							<span class="iconfont pad-10 fs-20 bg-color-ori color-fff">&#xe600;</span>
						</div>
						<div class="jumpButton-next swiper-right swiper-dis swiper-button-next" slot="button-next">
							<span class="iconfont pad-10 fs-20 bg-color-ori color-fff">&#xe601;</span>
						</div>
						<div class="swiper-pagination" slot="pagination"></div>
					</swiper>
				</div>
				<div class="min-show">
					<div class="brandArr-box">
						<div class="brandArr-demo-box" v-for="(item, index) in brandArr" :key="index" @click="toRouterBtn(item.partnerLink)">
							<img :src="item.partners" class="min-brand-img" alt />
						</div>
					</div>
				</div>
			</div>
			<div class="home-module home-banner">
				<div class="home-module-word">
					<div class="home-module-title">
						<h2 class="home-module-title">加入GOGO2GO，轻松开店</h2>
					</div>
					<button class="cta cursor-pointer" @click="toSettled()">
						<span class="hover-underline-animation">立即入驻</span>
						<i class="el-icon-right"></i>
					</button>
				</div>
			</div>
			<div class="popupWindow" v-if="hiddenPopup">
				<video autoplay="autoplay" controls="controls" :src="videoUrl"></video>
				<span class="iconfont color-fff popupSpan" @click.stop="hiddenPop">
					<i class="el-icon-close" color="#ffff" style="font-size: 32px"></i>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				videoUrl: '',
				ItemArray: [],
				rollArray: [],
				backImgData: {},
				brandArr: [],
				interestsData: [],
				weeklyReportsArr: [],
				setDataArr: [],
				scrollContent: {},
				swiperOptions: {
					slidesPerView: 2, // 显示个数
					slidesPerGroup: 1,
					loop: true,
					navigation: {
						nextEl: '.swiper-button-next2',
						prevEl: '.swiper-button-prev2',
					},
					direction: 'horizontal',
				},
				swiperOptionsMin: {
					slidesPerView: 1, // 显示个数
					slidesPerGroup: 1,
					loop: true,
					pagination: '.swiper-pagination',
					paginationClickable: true,
					direction: 'horizontal',
					navigation: {
						nextEl: '.swiper-button-next3',
						prevEl: '.swiper-button-prev3',
					},
				},
				swiperWraps: {
					slidesPerView: 4, // 显示个数
					slidesPerGroup: 4,
					loop: true,
					direction: 'horizontal',
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					pagination: {
						el: '.swiper-pagination',
					},
				},
				hiddenPopup: false,
				hoverImg: 0,
				loading: false,
				indValue: 0,
				backTrfSize: -100,
				backScaleSize: 2.8,

				centerOpacitySize: 1,

				imageOneOpacity: 0,
				imageOneScale: 3.0,
				imageOneTranY: 800,
				imageOneTranX: 1450,

				imageTwoOpacity: 0,
				imageTwoScale: 2.0,
				imageTwoTranY: -850,
				imageTwoTranX: -1000,

				imageThreeOpacity: 0,
				imageThreeScale: 2.5,
				imageThreeTranY: -200,
				imageThreeTranX: -650,

				imageFourOpacity: 0,
				imageFourScale: 2.6,
				imageFourTranY: -2200,
				imageFourTranX: 2600,
			}
		},
		computed: {
			isChinese() {
				switch (this.$i18n.locale) {
					case 'en_US':
						return false
					case 'zh_CN':
						return true
					default:
						return true
				}
			},
			getLangType() {
				return function(key) {
					switch (this.$i18n.locale) {
						case 'en_US':
							return 'en_' + key
						case 'zh_CN':
							return key
						default:
							return key
					}
				}
			},
		},
		mounted() {
			window.addEventListener('scroll', this.handleScrollX, true)
			// this.getValueKey()
		},
		methods: {
			stopRoll() {
				// this.$refs.moreImage.style.display = 'inline-block'
				// this.$refs.moreWidth.style.width = '48.78%'
			},
			removeRoll() {
				// this.$refs.moreImage.style.display = 'none'
				// this.$refs.moreWidth.style.width = '17.07%'
			},
			handleScrollX() {
				// console.log('滚动高度', window.pageYOffset)
				// console.log('距离顶部高度', this.$refs.pron.getBoundingClientRect().top)
				// console.log('滚动条距离顶部高度', document.documentElement.scrollTop);
				// 页面滚动距顶部距离
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
				let scroll = scrollTop - this.indValue
				this.indValue = scrollTop
				let startScrollHeight = 1200 // 开始向下滚动动画高度（向上滚动停止动画高度）
				let endScrollHeight = 1800 // 开始向上滚动动画高度（向下滚动停止动画高度）
				if (scrollTop > startScrollHeight && scroll > 0 && this.backScaleSize > 1) {
					this.backScaleSize = this.backScaleSize - (scrollTop - startScrollHeight) / 6000
					this.backTrfSize = this.backTrfSize + (scrollTop - startScrollHeight) / 200

					this.centerOpacitySize = this.centerOpacitySize - (scrollTop - startScrollHeight) / 5000

					this.imageOneOpacity = this.imageOneOpacity + (scrollTop - startScrollHeight) / 7000
					this.imageOneScale = this.imageOneScale - (scrollTop - startScrollHeight) / 5000
					this.imageOneTranX = Math.round(this.imageOneTranX - (scrollTop - startScrollHeight) / 50)
					this.imageOneTranY = Math.round(this.imageOneTranY - (scrollTop - startScrollHeight) / 13)

					this.imageTwoOpacity = this.imageTwoOpacity + (scrollTop - startScrollHeight) / 7000
					this.imageTwoScale = this.imageTwoScale - (scrollTop - startScrollHeight) / 7000
					this.imageTwoTranX = Math.round(this.imageTwoTranX + (scrollTop - startScrollHeight) / 7)
					this.imageTwoTranY = Math.round(this.imageTwoTranY + (scrollTop - startScrollHeight) / 100)

					this.imageThreeOpacity = this.imageThreeOpacity + (scrollTop - startScrollHeight) / 7000
					this.imageThreeScale = this.imageThreeScale - (scrollTop - startScrollHeight) / 5500
					this.imageThreeTranX = Math.round(this.imageThreeTranX + (scrollTop - startScrollHeight) / 6)

					this.imageFourOpacity = this.imageFourOpacity + (scrollTop - startScrollHeight) / 7000
					this.imageFourScale = this.imageFourScale - (scrollTop - startScrollHeight) / 5200
					this.imageFourTranX = Math.round(this.imageFourTranX - (scrollTop - startScrollHeight) / 100)
					this.imageFourTranY = Math.round(this.imageFourTranY + (scrollTop - startScrollHeight) / 6)
				} else if (scrollTop < endScrollHeight && scroll < 0 && this.backScaleSize < 2.8) {
					this.backScaleSize = this.backScaleSize + (endScrollHeight - scrollTop) / 6000
					this.backTrfSize = this.backTrfSize - (endScrollHeight - scrollTop) / 200

					this.centerOpacitySize = this.centerOpacitySize + (endScrollHeight - scrollTop) / 5000

					this.imageOneOpacity = this.imageOneOpacity - (endScrollHeight - scrollTop) / 7000
					this.imageOneScale = this.imageOneScale + (endScrollHeight - scrollTop) / 5000
					this.imageOneTranX = Math.round(this.imageOneTranX + (endScrollHeight - scrollTop) / 50)
					this.imageOneTranY = Math.round(this.imageOneTranY + (endScrollHeight - scrollTop) / 13)

					this.imageTwoOpacity = this.imageTwoOpacity - (endScrollHeight - scrollTop) / 7000
					this.imageTwoScale = this.imageTwoScale + (endScrollHeight - scrollTop) / 7000
					this.imageTwoTranX = Math.round(this.imageTwoTranX - (endScrollHeight - scrollTop) / 7)
					this.imageTwoTranY = Math.round(this.imageTwoTranY - (endScrollHeight - scrollTop) / 100)

					this.imageThreeOpacity = this.imageThreeOpacity - (endScrollHeight - scrollTop) / 7000
					this.imageThreeScale = this.imageThreeScale + (endScrollHeight - scrollTop) / 5500
					this.imageThreeTranX = Math.round(this.imageThreeTranX - (endScrollHeight - scrollTop) / 6)

					this.imageFourOpacity = this.imageFourOpacity - (endScrollHeight - scrollTop) / 7000
					this.imageFourScale = this.imageFourScale + (endScrollHeight - scrollTop) / 5200
					this.imageFourTranX = Math.round(this.imageFourTranX + (endScrollHeight - scrollTop) / 100)
					this.imageFourTranY = Math.round(this.imageFourTranY - (endScrollHeight - scrollTop) / 6)
				}
			},
			getValueKey() {
				const data = {
					type: 1,
				}
				this.loading = true
				this.$api
					.getProductList(data)
					.then((res) => {
						this.loading = false
						this.videoUrl = res.data.ceshi[0].videoValue
						this.backImgData = res.data.indexGif ? res.data.indexGif[0] : ''
						this.brandArr = res.data.partners
						this.rollArray = res.data.rollArray
						this.weeklyReportsArr = res.data.weeklyReports
						this.ItemArray = res.data.solution
						this.interestsData = res.data.interests
						this.setDataArr = res.data.inBusinessman
						this.scrollContent = res.data.scrollCentent ? res.data.scrollCentent[0] : ''
					})
					.catch((err) => {
						this.$message.error(err)
						this.loading = false
					})
			},
			prev(index) {
				this.$refs.mySwiper.$swiper.slidePrev(index)
				this.$refs.mySwiper2.$swiper.slidePrev(index)
			},
			next(index) {
				this.$refs.mySwiper.$swiper.slideNext(index)
				this.$refs.mySwiper2.$swiper.slideNext(index)
			},
			weekLink(link) {
				window.open(link, '_blank')
			},
			hiddenPop() {
				this.hiddenPopup = false
			},
			showPopup() {
				this.hiddenPopup = true
			},
			moverImg(index) {
				this.hoverImg = index
			},
			toSettled() {
				this.$router.push({
					path: '/settled'
				})
			},
			toRouterBtn(url) {
				window.open(url, '_blank')
			},
		},
		beforeDestroy() {
			window.removeEventListener('scroll', this.handleScrollX, true)
		},
	}
</script>

<style scoped>
	.video-notice {
	  font-size: 56px;
	  color: #000000;
	}
	.video-origin {
	  font-size: 20px;
	  color: #4285F7;
	}
	.cursor-pointer {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 33.33%;
	}

	.swiper-container {
		width: 100%;
		height: 100%;
	}

	.swiper-slide {
		text-align: center;
		font-size: 18px;
		background: #fff;
		height: 200px;
		padding: 20px 0;
		/* Center slide text vertically */
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
	}

	.swiper-slide-active {
		background-color: #fff;
		box-shadow: 0 4px 4px 0 rgba(98 98 98 0.02), 0 16px 32px 0 rgba(87 87 87 0.08);
		margin-right: 32px;
		/* display: inline-block; */
	}

	.swiper-slide-prev {
		background-color: #fff;
		box-shadow: 0 4px 4px 0 rgba(98 98 98 0.02), 0 16px 32px 0 rgba(87 87 87 0.08);
		width: 396px !important;
		margin-right: 32px;
		display: inline-block;
	}

	.swiper-slide-next {
		background-color: #fff;
		box-shadow: 0 4px 4px 0 rgba(98 98 98 0.02), 0 16px 32px 0 rgba(87 87 87 0.08);
		width: 396px !important;
		margin-right: 32px;
		/* display: inline-block; */
	}

	.containerDiv {
		width: 336px;
		padding: 20px;
	}

	.containerDiv:hover {
		border: 1px solid #ccc;
		box-shadow: 0 0 4px 0 #ccc;
	}

	.slideData {
		width: 25% !important;
		padding: 30px 0;
		margin: 50px 0;
	}

	.slideData:hover {
		box-shadow: 0px 10px 20px rgba(116, 114, 114, 0.23);
	}

	.requireSwiper {
		position: relative;
	}

	.swiper-left {
		position: absolute;
		z-index: 1000;
		background-color: #fcfcfc;
	}

	.swiper-right {
		position: absolute;
		right: 21px;
		z-index: 1000;
		background-color: #fcfcfc;
	}

	.swiper-dis {
		display: none;
	}

	.swiper-button-prev:after,
	.swiper-button-next:after {
		display: none !important;
	}

	.requireSwiper:hover .swiper-dis {
		display: inline-block;
	}

	.partner-wrap .swiper-slide {
		height: auto !important;
	}

	.popupWindow {
		position: fixed;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 9999999;
		width: 100%;
		height: 100%;
	}

	.popupWindow video {
		position: fixed;
		width: 60%;
		margin: 0 auto;
		left: 50%;
		transform: translate(-50%, -50%);
		top: 50%;
	}

	.popupSpan {
		position: fixed;
		right: 18%;
		top: 15%;
		font-size: 20px;
		cursor: pointer;
	}

	.hover-btn:hover {
		background: #4285f7;
	}

	.hover-btn:hover span {
		color: #fff;
	}

	.link-company-title::after {
		content: '';
		display: inline-block;
		width: 30px;
		height: 30px;
		background-image: url('../../assets/image/home/ic_img.png');
		background-size: 100%;
		position: relative;
		top: 13px;
		right: 23px;
		z-index: -100;
	}

	.home-title-hover {
		margin: 0 -20% !important;
		min-width: 1000px;
	}

	.min-show {
		display: none;
	}
	@media (max-width:1000px){
		.home-main-info{
			width: 100%;
		}
		.home-title-hover{
			margin: 0!important;
			min-width: auto;
			display: flex;
			flex-wrap: wrap;
		}
		.home-data-item{
			width: 90%!important;
			margin: 5%;
			background: #fff;
			z-index: 9999;
			box-shadow: 0 4px 4px 0 rgb(64 75 91 / 2%), 0 16px 32px 0 rgb(29 53 89 / 8%);
		}
		.home-data-long img{
			display: block;
		}
		.home-data-short{
			width: auto;
		}
		.home-title-hover .home-data-item{
			align-items: center;
		}
	}
	/*@media (max-width: 1000px) {*/
	/*  .home-title-hover {*/
	/*    display: none;*/
	/*  }*/
	/*  .min-show {*/
	/*    display: block !important;*/
	/*  }*/
	/*}*/
	/*@media screen and (max-width: 997px) {*/
	/*  .home-header-image {*/
	/*    top: 100% !important;*/
	/*  }*/
	/*  .home-header-image {*/
	/*    height: auto;*/
	/*  }*/
	/*  .home-video-notice {*/
	/*    !*padding-top: 2%;*!*/
	/*    top: 100px;*/
	/*    left: 0;*/
	/*    right: 0;*/
	/*    display: flex;*/
	/*    flex-direction: column;*/
	/*    justify-content: center;*/
	/*    align-items: center;*/
	/*  }*/
	/*  .button-two {*/
	/*    flex-direction: column;*/
	/*    align-items: center;*/
	/*  }*/
	/*  !*.video-origin {*!*/
	/*  !*  font-size: 2rem;*!*/
	/*  !*}*!*/
	/*  .adaptive-main {*/
	/*    top: 90%;*/
	/*  }*/
	/*  !*.video-notice {*!*/
	/*  !*  width: auto;*!*/
	/*  !*  font-size: 2rem;*!*/
	/*  !*}*!*/
	/*  .home-main-info {*/
	/*    width: 100%;*/
	/*  }*/

	/*  .lun-img {*/
	/*    width: 90%;*/
	/*    margin: 0 auto;*/
	/*  }*/
	/*  .lun-img img {*/
	/*    width: 100%;*/
	/*  }*/
	/*  .web-show {*/
	/*    display: none !important;*/
	/*  }*/
	/*  .home-main-info {*/
	/*    padding-bottom: 20px;*/
	/*  }*/
	/*  .home-data-part {*/
	/*    flex: 0;*/
	/*  }*/
	/*  .adaptive-choose {*/
	/*    margin-bottom: 0;*/
	/*  }*/
	/*  .home-seller {*/
	/*    padding: 0;*/
	/*  }*/
	/*  .swiper-div .swiper-slide {*/
	/*    height: auto;*/
	/*    padding: 0;*/
	/*  }*/
	/*  .swiper-slide-prev,*/
	/*  .swiper-slide-active {*/
	/*    width: 100% !important;*/
	/*    margin-right: 0;*/
	/*  }*/
	/*  .adaptive-choose {*/
	/*    margin: 0;*/
	/*    padding-bottom: 100px;*/
	/*  }*/
	/*  .centralToChoose {*/
	/*    margin-top: 50px;*/
	/*  }*/
	/*  .brandArr-box {*/
	/*    width: 100%;*/
	/*    display: flex;*/
	/*    overflow: scroll;*/
	/*  }*/
	/*  .brandArr-demo-box {*/
	/*    margin: 20px 20px;*/
	/*  }*/
	/*  .min-brand-img {*/
	/*    width: 110px;*/
	/*    padding: 30px 30px;*/
	/*    margin-left: 0.32rem;*/
	/*    box-shadow: 0 10px 10px 0 rgb(98 98 98 / 2%),*/
	/*      0 16px 32px 0 rgb(87 87 87 / 8%);*/
	/*  }*/
	/*  .home-partner {*/
	/*    width: 100%;*/
	/*  }*/
	/*  .popupWindow video {*/
	/*    position: fixed;*/
	/*    width: 100%;*/
	/*    margin: 0 auto;*/
	/*    left: 50%;*/
	/*    transform: translate(-50%, -50%);*/
	/*    top: 50%;*/
	/*  }*/
	/*  .popupSpan {*/
	/*    position: fixed;*/
	/*    right: 0;*/
	/*    top: 34%;*/
	/*    font-size: 20px;*/
	/*    cursor: pointer;*/
	/*  }*/
	/*  .icon-data-type {*/
	/*    position: absolute;*/
	/*    top: -20px;*/
	/*    width: 40px;*/
	/*    height: 40px;*/
	/*  }*/
	/*}*/

	.item__1zkDK {
		position: absolute;
		top: 0;
		left: 0;
	}

	.animate-box-shadow {
		box-shadow: 0 10px 10px 0 rgb(64 75 91 / 2%), 0 16px 32px 0 rgb(29 53 89 / 8%);
	}

	.iNAsFOsRx9 {
		border-top-left-radius: 34px;
		border-top-right-radius: 34px;
		border-bottom-left-radius: 34px;
		border-bottom-right-radius: 34px;
		width: 981px;
		height: 688px;
	}

	.JLsfXkagL4 {
		width: 240px;
		height: 240px;
	}

	.snMLzW9nMO {
		height: 100%;
		border-top-left-radius: 45px;
		border-top-right-radius: 45px;
		border-bottom-left-radius: 45px;
		border-bottom-right-radius: 45px;
		width: 300px;
	}

	.AZlwEUmi9b {
		height: 100%;
		border-top-left-radius: 85px;
		border-top-right-radius: 85px;
		border-bottom-left-radius: 85px;
		border-bottom-right-radius: 85px;
		width: 550px;
	}

	.ahBFJQpZfJ {
		height: 100%;
		border-top-left-radius: 45px;
		border-top-right-radius: 45px;
		border-bottom-left-radius: 45px;
		border-bottom-right-radius: 45px;
		width: 470px;
	}

	.T9nfP9hbVY {
		height: 100%;
		border-top-left-radius: 85px;
		border-top-right-radius: 85px;
		border-bottom-left-radius: 85px;
		border-bottom-right-radius: 85px;
		width: 500px;
	}
</style>
